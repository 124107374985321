import React from "react";
// import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout/layout";
// import Hero from "../components/rsvpHero";
import moment from "moment";
import Fade from "react-reveal/Fade";
import TextField from "@mui/material/TextField";
import Hero from "../components/pageContent/contact/hero";
import ContactBody from "../components/pageContent/contact/body";

const ContactPage = () => {
  const query = useStaticQuery(graphql`
    query ContactQuery {
      contentfulAsset(title: { in: "contact page hero" }) {
        fluid(maxWidth: 1980, maxHeight: 1280) {
          ...GatsbyContentfulFluid_tracedSVG
        }
        title
      }
    }
  `);

  return (
    <Layout>
      <Helmet title='My ADU Story - Contact' />
      <Hero data={query.contentfulAsset} />
      {/* <ContactBody /> */}
      {/* <Fade delay={1100}></Fade> */}
    </Layout>
  );
};

export default ContactPage;
